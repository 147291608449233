import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { ThemeProvider } from "../context/ThemeContext";
import "../styles/globals.scss";

//const GTM_ID = "GTM-K35QXRVV"; // Replace with your GTM ID

function MyApp({ Component, pageProps }) {
  //const router = useRouter();

  // useEffect(() => {
  //   const handleRouteChange = (url) => {
  //     if (process.env.NODE_ENV === "production") {
  //       window.dataLayer = window.dataLayer || [];
  //       window.dataLayer.push({ event: "pageview", page: url });
  //     }
  //   };

  //   router.events.on("routeChangeComplete", handleRouteChange);
  //   return () => {
  //     router.events.off("routeChangeComplete", handleRouteChange);
  //   };
  // }, [router.events]);

  return (
    <ThemeProvider>
      {/* <Layout>‚ */}
        {/* Load Google Tag Manager only in production */}
        {/* {process.env.NODE_ENV === "production" && (
          <Script
            id="gtm-script"
            strategy="beforeInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${GTM_ID}');
              `,
            }}
          />
        )} */}

        <Component {...pageProps} />
      {/* </Layout> */}
    </ThemeProvider>
  );
}

export default MyApp;
